<template>
    <Toast />
	<div class="grid p-fluid">
		<div class="col-12 md:col-6">
			<div class="card">
				<h5 style="margin-bottom: 5px;">Configuración del sistema</h5>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-12 lg:mb-0" style="margin-bottom: 1rem !important;">
						<h6 style="margin: 0.8rem 0rem;"><b>Proformas</b></h6>
						<div class="grid formgrid" v-for="device in this.devices" :key="device.id">
							<div class="col-12 mb-2 lg:col-8 lg:mb-0">
								<div style="width: 100%;">
									<label style="margin: 0.2rem 0rem;">
										Enviar proforma por correo automáticamente
									</label><br>
								</div>
							</div>
							<div class="col-12 mb-2 lg:col-4 lg:mb-0">
								<div style="display: flex; text-align: right; padding: 0.2rem 0rem;">
									<div style="margin: 0px 0px 0px auto; width: 1px;"></div>
									<InputSwitch v-model="this.business.defaults.settings.proforma_sendAutoEmail" />
								</div>
							</div>
						</div>
					</div>
					<Button label="Guardar" :loading="isSaveDefaultsButtonLoading" style="width: auto; margin: 0px 0px 0px auto !important;" class="p-button-raised p-button-success mr-2 mb-2"  @click="saveDefaults()"/>
				</div>
			</div>
			<div class="card">
				<h5 style="margin-bottom: 5px;">Impresora de Ticket</h5>
				<small style="font-style: italic; color: gray;">Compatible con impresoras EPSON</small>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-12 lg:mb-0">
						<label v-if="this.devices !== null && this.devices.length === 0" style="margin-top: 20px;">
							No hay dispositivos registrados
						</label>
						<div class="grid formgrid" v-for="device in this.devices" :key="device.id">
							<div class="col-12 mb-2 lg:col-8 lg:mb-0">
								<div style="width: 100%;">
									<label style="margin: 1.5rem 0rem 0.2rem 0rem;"><b>{{ device.name }}</b></label><br>
									<label :style="{color: ((device.connected) ? 'green' : 'red')}">{{ device.connected ? "Conectado" : "Desconectado" }}</label>
								</div>
							</div>
							<div class="col-12 mb-2 lg:col-4 lg:mb-0">
								<div style="display: flex; text-align: right; padding: 1.5rem 0px;">
									<div style="margin: 0px 0px 0px auto; width: 1px;"></div>
									<RadioButton name="device" :value="device" v-model="this.selectedDevice" style="padding: 10px 0px; margin-left: 20px;" @click="onSelectDevice(device.id)"/>
								</div>
							</div>
							<div style="margin-top: 5px; width: 100%; height: 1px; background-color: lightgray"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 md:col-6">
			<div class="card">
				<h5 style="margin-bottom: 5px;">Actualización de contraseña</h5>
				<div class="grid formgrid">
					<div class="col-12 mb-2 lg:col-12 lg:mb-0">
						<h6 style="margin: 1.5rem 0rem 0.5rem 0rem;">Actual contraseña</h6>
						<Password placeholder="Contraseña" v-model="this.passwords.old" :feedback="false" :toggleMask="true" :class="{'w-full': true, 'mb-3': true}" inputClass="w-full" inputStyle="padding:1rem"></Password>
					</div>
					<div class="col-12 mb-2 lg:col-12 lg:mb-0">
						<h6 style="margin: 1.5rem 0rem 0.5rem 0rem;">Nueva contraseña</h6>
						<Password placeholder="Contraseña (mínimo 8 caracteres)" v-model="this.passwords.new" :feedback="false" :toggleMask="true" :class="{'w-full': true, 'mb-3': true}" inputClass="w-full" inputStyle="padding:1rem"></Password>
					</div>
					<Button label="Guardar" :loading="isUpdatePasswordButtonLoading" style="width: auto; margin: 1rem 0rem 0rem auto !important;" class="p-button-raised p-button-success mr-2 mb-2"  @click="updatePassword()"/>
				</div>
			</div>
		</div>
	</div>

	<!-- Login after password update -->
	<Dialog header="Alerta" v-model:visible="displayLoginDialog" :style="{width: '350px'}" :modal="true" :closeOnEscape="false" :closable="false" >
		<div class="flex align-items-center justify-content-center">
			<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
			<span>Debido a la actualización de contraseña, se requiere que inicie sesión nuevamente</span>
		</div>
		<template #footer>
			<Button label="Iniciar Sesión" icon="pi pi-check" @click="onLoginAgain" class="p-button-text" autofocus />
		</template>
	</Dialog>
</template>
<script>
	import httpRequest from '../../service/httpRequest';
	import Device from '../../models/device';
	import Business from '../../models/business';

	export default {
		data() {
			return {
				devices: [],
				selectedDevice: null,
				business: new Business(),
				passwords: {},

				// Loading
				isSaveDefaultsButtonLoading: false,
				isUpdatePasswordButtonLoading: false,

				// Show
				displayLoginDialog: false,
			}
		},
		async created() {
			this.getDevices();
			this.business = await this.$store.getters.business;
		},
		methods: {
			async getDevices() {
				const response = await httpRequest.getDevices();
				if (response.status === 200) {
					this.devices = response.data.map(device => new Device(device))
					// find selected
					for (var i=0; i<this.devices.length; i++) {
						if (this.devices[i].selected) {
							this.selectedDevice = new Device(this.devices[i]);
						}
					}
				}
			},
			async onSelectDevice(id) {
				const response = await httpRequest.selectDevice(id);
				if (response.status === 200) {
					this.$toast.add({severity:'success', summary: 'Impresora seleccionada', detail: '', life: 3000});

					let device = new Device(response.data);
					this.selectedDevice = device;
					for (var i=0; i<this.devices.length; i++) {
						if (this.devices[i].id === device.id) {
							this.devices[i] = device;
						}
					}
				}
			},
			async saveDefaults() {
				this.isSaveDefaultsButtonLoading = true;
				const response = await httpRequest.saveDefaults(this.business.defaults);
				this.isSaveDefaultsButtonLoading = false;
				const title = (response.status === 200) ? "Éxito" : "Error";
				const message = (response.status === 200) ? "Su configuración ha sido guardada." : "Error guardando su configuración, por favor intente nuevamente."
				this.$toast.add({severity:(response.status === 200) ? 'success' : 'error', summary: title, detail: message, life: 3000});

				// Store changes in local memory
				if (response.status === 200) { await this.$store.dispatch('setBusiness', this.business) }
			},
			async updatePassword() {
				if (this.passwords.old === undefined || this.passwords.old === "") {
					this.$toast.add({severity: 'error', summary: "Error", detail: "Ingrese su contraseña actual", life: 3000});
				} else if (this.passwords.new === undefined || this.passwords.new === "") {
					this.$toast.add({severity: 'error', summary: "Error", detail: "Ingrese su nueva contraseña", life: 3000});
				} else if (this.passwords.new.length < 8) {
					this.$toast.add({severity: 'error', summary: "Error", detail: "Su nueva contraseña tiene que ser mayor o igual a 8 caracteres", life: 6000});
				} else {
					this.isUpdatePasswordButtonLoading = true;
					const response = await httpRequest.updatePassword(this.passwords.old, this.passwords.new);
					this.isUpdatePasswordButtonLoading = false;
					if (response.status === 200) {
						this.$toast.add({severity:'success', summary: 'Contraseña actualizada', detail: '', life: 3000});
						this.displayLoginDialog = true;
					} else {
						this.$toast.add({severity:'error', summary: 'Error', detail: 'Error actualizando contraseña, intente nuevamente.', life: 3000});
					}
				}
			},
			async onLoginAgain() {
				await this.$store.dispatch('Logout');
				this.$router.push('/login');
			},
		}
	}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}

@media screen and (max-width: 760px) {
    .creds-container {
        width: 90%;
    }
}
</style>