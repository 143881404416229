const Device = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null
            this.name = null
            this.selected = null
            this.connected = null
        } else {
            this.id = object.id
            this.name = object.name
            this.selected = object.selected
            this.connected = object.connected
        }
    }
}

export default Device